import { render, staticRenderFns } from "./ProjectNavigation.vue?vue&type=template&id=514b4a62&scoped=true&"
import script from "./ProjectNavigation.vue?vue&type=script&lang=ts&"
export * from "./ProjectNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectNavigation.vue?vue&type=style&index=0&id=514b4a62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514b4a62",
  null
  
)

export default component.exports